import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"
import {
  setPublisherFormSubmittingFlag,
  updatePublisher,
  updatePublisherFailed,
  updatePublisherSellersJsonFailed,
  updatePublisherSuccess,
} from "../actions/publisherActions"

import { AxiosRequestConfig } from "axios"
import { PayloadAction } from "@reduxjs/toolkit"
import { Publisher } from "../types/publisherTypes"
import { SagaIterator } from "redux-saga"
import _ from "lodash"
import { clearErrors } from "../actions/errorActions"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* updatePublisherSaga(action: PayloadAction<Publisher>) {
  const publisher = action.payload
  // data manipulation in the saga despite being against best practices due to controlled -> non-controlled component test warning
  publisher.sellersJsonEntry?.isConfidential &&
    (publisher.sellersJsonEntry = {
      ..._.omit(publisher.sellersJsonEntry, "name", "domain"),
    })
  const ajaxConfig: AxiosRequestConfig = {
    method: "PATCH",
    url: `${API_ROOT}${API.V1}${SECTION.ADMIN}/publishers/${action.payload.id}`,
    data: {
      publisher: action.payload,
    },
  }
  yield put(clearErrors())
  yield put(setPublisherFormSubmittingFlag(true))
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(updatePublisherSuccess(response.data.publisher))

    // If SellersJson error occurs in backend, response.data.message is populated with the ServiceError.
    // Overdrive DB transaction/data update is not rolled back
    if (response.data.message) {
      yield put(updatePublisherSellersJsonFailed(response.data.message))
    }
  } catch (err) {
    yield handleSagaError(updatePublisherFailed, err)
  }
  yield put(setPublisherFormSubmittingFlag(false))
}

export function* watchUpdatePublisher(): SagaIterator {
  yield takeEvery(updatePublisher, updatePublisherSaga)
}
