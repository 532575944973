import { Grid, Typography } from "@material-ui/core"

import React from "react"
import styles from "./styles"
import { withStyles } from "@material-ui/core/styles"

const Maintenance = ({
  classes,
  message = "Server not responding. Please try again later by refreshing this page.",
}: {
  classes
  message?: string
}) => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      {message && (
        <Grid item xs={3}>
          <Typography variant="h1" component="h3" className={classes.message}>
            {message}
          </Typography>
        </Grid>
      )}
      <Grid item xs={3}>
        <img alt="Logo" src="/assets/logoBlackRed.svg" className={classes.logo} />
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Maintenance)
