import React from "react"
import { withStyles } from "@material-ui/core/styles"
import styles from "./styles"
import { Grid, Typography } from "@material-ui/core"

const NotAllowed = ({ classes }) => {
  const permissionMissingMessage = "Page not found"
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100%" }}
    >
      <Grid item>
        <Typography variant="h1" component="h3" align="center">
          {permissionMissingMessage}
        </Typography>
      </Grid>

      <Grid item>
        <img alt="Logo" src="/assets/logoBlackRed.svg" className={classes.logo} />
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(NotAllowed)
