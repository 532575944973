import AppNexusMigrationForm from "./components/Main/Admin/Publisher/AppNexusMigrationForm"
import AudienceForm from "./components/Main/Tools/Audience/AudienceForm"
import AudiencesList from "./components/Main/Tools/Audience/AudiencesList"
import CampaignBI from "./components/Main/DataViews/CampaignBI/CampaignBI"
import Can from "./components/common/Can"
import ChangeLog from "./components/Main/Admin/ChangeLog"
import CompaniesList from "./components/Main/Admin/Company/CompaniesList"
import CompanyFormWrapper from "./components/Main/Admin/Company/CompanyFormWrapper"
import ConfigurationWrapper from "./components/Main/Admin/ConfigurationWrapper"
import DealForm from "./components/Main/Tools/Deal/DealForm"
import DealRequestList from "./components/Main/Tools/DealRequest/DealsRequestList"
import DealsList from "./components/Main/Tools/Deal/DealsList"
import DomainFormWrapper from "./components/Main/Admin/Domain/DomainFormWrapper"
import DomainsList from "./components/Main/Admin/Domain/DomainsList"
import HomePage from "./components/Main/HomePage"
import Logout from "./components/Logout"
import Main from "./components/Main"
import NotAllowed from "./components/common/NotAllowed"
import { PERMISSIONS } from "./constants"
import PublisherFormWrapper from "./components/Main/Admin/Publisher/PublisherFormWrapper"
import PublishersList from "./components/Main/Admin/Publisher/PublishersList"
import React from "react"
import RoleForm from "./components/Main/Admin/Role/RoleForm"
import RolesList from "./components/Main/Admin/Role/RolesList"
import { RouteConfig } from "react-router-config"
import Segment from "./components/Main/Admin/Segment/Segment"
import SellersJsonList from "./components/Main/Admin/Publisher/SellersJsonList"
import SiteFormWrapper from "./components/Main/Admin/Publisher/SiteFormWrapper"
import SubdomainForm from "./components/Main/Admin/Domain/DomainFormWrapper/SubdomainForm"
import TagForm from "./components/Main/Admin/Publisher/SiteFormWrapper/TagForm"
import UserForm from "./components/Main/Admin/User/UserForm"
import UsersList from "./components/Main/Admin/User/UsersList"

// Admin Routes
const userRoutes = [
  {
    path: "/admin/users",
    exact: true,
    render: () => (
      <Can perform={PERMISSIONS.ADMIN.USER.LIST.VIEW} yes={<UsersList />} no={<NotAllowed />} />
    ),
  },
  {
    path: "/admin/users/create",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.USER.CREATE}
        yes={<UserForm match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/users/:userId",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.USER.VIEW}
        yes={<UserForm match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/users/:userId/edit",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.USER.UPDATE}
        yes={<UserForm match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
]

const roleRoutes = [
  // role routes
  {
    path: "/admin/roles",
    exact: true,
    render: () => (
      <Can perform={PERMISSIONS.ADMIN.ROLE.LIST.VIEW} yes={<RolesList />} no={<NotAllowed />} />
    ),
  },
  {
    path: "/admin/roles/create",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.ROLE.CREATE}
        yes={<RoleForm match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/roles/:roleId",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.ROLE.VIEW}
        yes={<RoleForm match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/roles/:roleId/edit",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.ROLE.UPDATE}
        yes={<RoleForm match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
]

const companyRoutes = [
  {
    path: "/admin/companies",
    exact: true,
    render: () => (
      <Can
        perform={PERMISSIONS.ADMIN.COMPANY.LIST.VIEW}
        yes={<CompaniesList />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/companies/create",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.COMPANY.CREATE}
        yes={<CompanyFormWrapper match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/companies/:companyId",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.COMPANY.VIEW}
        yes={<CompanyFormWrapper match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/companies/:companyId/edit",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.COMPANY.UPDATE}
        yes={<CompanyFormWrapper match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/companies/:companyId/campaign-bi-settings/edit",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.COMPANY.UPDATE}
        yes={<CompanyFormWrapper match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
]

const publisherRoutes = [
  {
    path: "/admin/publishers",
    exact: true,
    render: () => (
      <Can
        perform={PERMISSIONS.ADMIN.PUBLISHER.LIST.VIEW}
        yes={<PublishersList />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/publishers/create",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.PUBLISHER.CREATE}
        yes={<PublisherFormWrapper match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/publishers/sellers-json",
    exact: true,
    render: () => (
      <Can
        perform={PERMISSIONS.ADMIN.PUBLISHER.LIST.VIEW}
        yes={<SellersJsonList />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/publishers/app-nexus-migration",
    exact: true,
    render: () => (
      <Can
        perform={PERMISSIONS.ADMIN.PUBLISHER.CREATE}
        yes={<AppNexusMigrationForm />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/publishers/:publisherId",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.PUBLISHER.VIEW}
        yes={<PublisherFormWrapper match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/publishers/:publisherId/edit",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.PUBLISHER.UPDATE}
        yes={<PublisherFormWrapper match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  // {
  //   path: "/admin/publishers/:publisherId/copy",
  //   exact: true,
  //   render: (props) => (
  //     <Can
  //       perform={PERMISSIONS.TOOLS.PUBLISHER.CREATE}
  //       yes={<PublisherFormWrapper match={props.match} />}
  //       no={<NotAllowed />}
  //     />
  //   ),
  // },

  // Site Routes
  {
    path: "/admin/publishers/:publisherId/sites",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.PUBLISHER.LIST.VIEW}
        yes={<PublisherFormWrapper match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/publishers/:publisherId/sites/create",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.PUBLISHER.CREATE}
        yes={<SiteFormWrapper match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/publishers/:publisherId/sites/:siteId",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.PUBLISHER.VIEW}
        yes={<SiteFormWrapper match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/publishers/:publisherId/sites/:siteId/edit",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.PUBLISHER.UPDATE}
        yes={<SiteFormWrapper match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },

  // Tag Routes
  {
    path: "/admin/publishers/:publisherId/sites/:siteId/tags",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.PUBLISHER.VIEW}
        yes={<SiteFormWrapper match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/publishers/:publisherId/sites/:siteId/tags/create",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.PUBLISHER.LIST.VIEW}
        yes={<TagForm match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/publishers/:publisherId/sites/:siteId/tags/:tagId",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.PUBLISHER.LIST.VIEW}
        yes={<TagForm match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/publishers/:publisherId/sites/:siteId/tags/:tagId/edit",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.PUBLISHER.UPDATE}
        yes={<TagForm match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
]

const domainRoutes = [
  {
    path: "/admin/domains",
    exact: true,
    render: () => (
      <Can perform={PERMISSIONS.ADMIN.DOMAIN.LIST.VIEW} yes={<DomainsList />} no={<NotAllowed />} />
    ),
  },
  {
    path: "/admin/domains/create",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.DOMAIN.CREATE}
        yes={<DomainFormWrapper match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/domains/:domainId",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.DOMAIN.VIEW}
        yes={<DomainFormWrapper match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/domains/:domainId/edit",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.DOMAIN.UPDATE}
        yes={<DomainFormWrapper match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/domains/:domainId/subdomains",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.DOMAIN.VIEW}
        yes={<DomainFormWrapper match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/domains/:domainId/subdomains/create",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.DOMAIN.UPDATE}
        yes={<SubdomainForm match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/domains/:domainId/subdomains/:subdomainId",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.DOMAIN.VIEW}
        yes={<SubdomainForm match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/domains/:domainId/subdomains/:subdomainId/edit",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.DOMAIN.UPDATE}
        yes={<SubdomainForm match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
]

// Tools Routes
const audienceRoutes = [
  {
    path: "/tools/audiences",
    exact: true,
    render: () => (
      <Can
        perform={PERMISSIONS.TOOLS.AUDIENCE.LIST.VIEW}
        yes={<AudiencesList />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/tools/audiences/create",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.TOOLS.AUDIENCE.CREATE}
        yes={<AudienceForm match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/tools/audiences/:audienceId",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.TOOLS.AUDIENCE.VIEW}
        yes={<AudienceForm match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/tools/audiences/:audienceId/edit",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.TOOLS.AUDIENCE.UPDATE}
        yes={<AudienceForm match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/tools/audiences/:audienceId/copy",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.TOOLS.AUDIENCE.CREATE}
        yes={<AudienceForm match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
]

const dealRoutes = [
  {
    path: "/tools/deals",
    exact: true,
    render: () => (
      <Can perform={PERMISSIONS.TOOLS.DEAL.LIST.VIEW} yes={<DealsList />} no={<NotAllowed />} />
    ),
  },
  {
    path: "/tools/deals/create",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.TOOLS.DEAL.CREATE}
        yes={<DealForm match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/tools/deals/:dealId",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.TOOLS.DEAL.VIEW}
        yes={<DealForm match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/tools/deals/:dealId/edit",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.TOOLS.DEAL.UPDATE}
        yes={<DealForm match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/tools/deals/:dealId/copy",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.TOOLS.DEAL.CREATE}
        yes={<DealForm match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
]

const dealRequestRoutes = [
  {
    path: "/tools/deal-requests",
    exact: true,
    render: () => (
      <Can
        perform={PERMISSIONS.TOOLS.DEAL_REQUEST.LIST.VIEW}
        yes={<DealRequestList />}
        no={<NotAllowed />}
      />
    ),
  },
]

const configurationRoutes = [
  {
    path: "/admin/configuration/home-page-editor",
    exact: true,
    render: (props) => (
      <Can
        perform={PERMISSIONS.ADMIN.CONFIGURATION.UPDATE}
        yes={<ConfigurationWrapper match={props.match} />}
        no={<NotAllowed />}
      />
    ),
  },
  {
    path: "/admin/configuration/feature-flags",
    exact: true,
    render: (props) => (
      <Can yes={<ConfigurationWrapper match={props.match} />} no={<NotAllowed />} />
    ),
  },
]

const routes = (userDataViews = []): RouteConfig[] => {
  const dataViews = [
    // render data view dashboard routes based on user's permissions/data view access
    ...userDataViews.map((dataView) => ({
      path: dataView.overdriveRoute,
      exact: true,
      // replace with GeneralDashboard component; doesn't need permissions because routes are dynamically generated based on permissions
      render: () => <CampaignBI dataView={dataView} />,
    })),
  ]
  return [
    {
      path: "/logout",
      exact: true,
      render: () => <Logout message={""} />,
    },
    {
      path: "/",
      component: Main,
      routes: [
        // Home Page
        {
          path: "/",
          exact: true,
          render: () => <HomePage />,
        },
        ...userRoutes,
        ...roleRoutes,
        ...companyRoutes,
        ...audienceRoutes,
        ...dealRoutes,
        ...dealRequestRoutes,
        ...publisherRoutes,
        ...domainRoutes,
        {
          path: "/admin/changelog",
          exact: true,
          render: () => (
            <Can
              perform={PERMISSIONS.ADMIN.CHANGELOG.LIST.VIEW}
              yes={<ChangeLog />}
              no={<NotAllowed />}
            />
          ),
        },
        {
          path: "/admin/segments",
          exact: true,
          render: () => (
            <Can
              perform={PERMISSIONS.ADMIN.SEGMENT.CREATE.UPLOAD}
              yes={<Segment />}
              no={<NotAllowed />}
            />
          ),
        },
        ...configurationRoutes,
        ...dataViews,
        //Wildcard route for any page if user does not have permission or route does not exist
        {
          path: "*",
          exact: true,
          render: () => <NotAllowed />,
        },
      ],
    },
  ]
}

export default routes
