import appNexusMigrationReducer from "./appNexusMigrationFormReducer"
import applicationVersionReducer from "./applicationVersionReducer"
import audienceFormReducer from "./audienceFormReducer"
import audiencesReducer from "./audiencesReducer"
import changeLogReducer from "./changeLogReducer"
import { combineReducers } from "redux"
import companiesReducer from "./companiesReducer"
import companyFormReducer from "./companyFormReducer"
import currentUserReducer from "./currentUserReducer"
import dataViewReducer from "./dataViewReducer"
import dealFormReducer from "./dealFormReducer"
import dealReducer from "./dealReducer"
import dealRequestsReducer from "./dealRequestsReducer"
import domainFormReducer from "./domainFormReducer"
import domainReducer from "./domainReducer"
import errorReducer from "./errorReducer"
import featureFlagReducer from "./featureFlagReducer"
import homePageReducer from "./homePageReducer"
import permissionReducer from "./permissionReducer"
import pricingEstimateReducer from "./pricingEstimateReducer"
import publisherFormReducer from "./publisherFormReducer"
import publisherReducer from "./publisherReducer"
import roleFormReducer from "./roleFormReducer"
import roleReducer from "./roleReducer"
import segmentReducer from "./segmentReducer"
import segmentVendorsReducer from "./segmentVendorReducer"
import sellersJsonReducer from "./sellersJsonReducer"
import siteFormReducer from "./siteFormReducer"
import siteReducer from "./siteReducer"
import subdomainFormReducer from "./subdomainFormReducer"
import subdomainReducer from "./subdomainReducer"
import tagFormReducer from "./tagFormReducer"
import tagReducer from "./tagReducer"
import userFormReducer from "./userFormReducer"
import userReducer from "./userReducer"

export default combineReducers({
  users: userReducer,
  userForm: userFormReducer,
  roles: roleReducer,
  roleForm: roleFormReducer,
  companies: companiesReducer,
  companyForm: companyFormReducer,
  domains: domainReducer,
  domainForm: domainFormReducer,
  subdomains: subdomainReducer,
  subdomainForm: subdomainFormReducer,
  errors: errorReducer,
  permissions: permissionReducer,
  currentUser: currentUserReducer,
  changeLog: changeLogReducer,
  audienceForm: audienceFormReducer,
  audiences: audiencesReducer,
  deals: dealReducer,
  dealForm: dealFormReducer,
  publishers: publisherReducer,
  publisherForm: publisherFormReducer,
  sellersJson: sellersJsonReducer,
  sites: siteReducer,
  siteForm: siteFormReducer,
  tags: tagReducer,
  tagForm: tagFormReducer,
  segments: segmentReducer,
  pricingEstimate: pricingEstimateReducer,
  segmentVendors: segmentVendorsReducer,
  applicationVersion: applicationVersionReducer,
  homePage: homePageReducer,
  dataView: dataViewReducer,
  dealRequests: dealRequestsReducer,
  appNexusMigrationForm: appNexusMigrationReducer,
  featureFlags: featureFlagReducer,
})
